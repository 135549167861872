import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';

import {
    TestimonialSlideWrapper,
    TestimonialItem,
    TestimonialMeta,
    AuthorInfo,
    AuthorImage,
} from './testimonial.style';
import {SectionHeader} from "../../AppMinimal/app-minimal.style";

const TestimonialSection = ({
                                sectionWrapper,
                                secTitleWrapper,
                                secText,
                                secHeading,
                                reviewTitle,
                                review,
                                name,
                                designation,
                            }) => {
    const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        TESTIMONIALS {
          name
          designation
          review
          title
          avatar {
            publicURL
          }
        }
      }
    }
  `);

    const carouselOptions = {
        type: 'carousel',
        autoplay: 0,
        perView: 3,
        gap: 30,
        animationDuration: 800,
        breakpoints: {
            990: {
                perView: 1,
            },
        },
    };

    return (
        <Box {...sectionWrapper} as="section" id="testimonial_section">
            <Container>
                <h4 style={{margin: 0, fontSize:"14px",padding: 0, color: "#2678DD"}}>TESTIMONIAL</h4>
                <h3 style={{fontSize: "40px", marginTop: "10px", padding: 0}}>Over 5,000 Satisfied Clients</h3>

                <TestimonialSlideWrapper>
                    <GlideCarousel
                        options={carouselOptions}
                        carouselSelector="testimonial__slider"
                        controls={false}
                        bullets={true}
                        numberOfBullets={Data.saasModernJson.TESTIMONIALS.length}
                    >
                        <>
                            {Data.saasModernJson.TESTIMONIALS.map((item, index) => (
                                <GlideSlide key={`testimonial-slide-${index}`}>
                                    <TestimonialItem>
                                        <AuthorImage>
                                            <Image
                                                src={item.avatar.publicURL}
                                                alt={`reviewer-image-${index}`}
                                            />
                                        </AuthorImage>
                                        <Text content={item.review} {...review} />
                                        <TestimonialMeta>
                                            <AuthorInfo>
                                                <Box>
                                                    <Heading as="h3" content={item.name} {...name} />
                                                    <Text content={item.designation} {...designation} />
                                                </Box>
                                            </AuthorInfo>
                                        </TestimonialMeta>
                                    </TestimonialItem>
                                </GlideSlide>
                            ))}
                        </>
                    </GlideCarousel>
                </TestimonialSlideWrapper>
            </Container>
        </Box>
    );
};

TestimonialSection.propTypes = {
    sectionHeader: PropTypes.object,
};

TestimonialSection.defaultProps = {
    sectionWrapper: {
        pt: ['60px', '80px', '90px', '100px', '100px'],
        pb: ['60px', '80px', '90px', '100px', '100px'],
    },
    secTitleWrapper: {
        mb: ['40px', '60px'],
    },
    secText: {
        as: 'span',
        display: 'block',
        textAlign: 'center',
        fontSize: '14px',
        letterSpacing: '0.15em',
        fontWeight: '700',
        color: '#2aa275',
        mb: '5px',
    },
    secHeading: {
        textAlign: 'center',
        fontSize: ['20px', '24px'],
        fontWeight: '500',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mb: '0',
        lineHeight: '1.67',
    },
    reviewTitle: {
        fontSize: ['15px', '16px'],
        fontWeight: '500',
        color: '#343d48',
        lineHeight: '1.5',
        mb: '13px',
    },
    review: {
        fontSize: ['16px', '19px'],
        fontWeight: '300',
        color: '#343d48',
        lineHeight: '1.7',
        mb: 0,
    },
    name: {
        fontSize: ['14px', '16px'],
        fontWeight: '500',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mb: '8px',
    },
    designation: {
        fontSize: ['12px', '14px'],
        color: '#6f7a87',
        mb: 0,
    },
};

export default TestimonialSection;
